/* results in font jumping
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300..900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700");
@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300..700");

:root {
    --white: #fdf0ed;
    --whiter: #fdf0edde;
    --scrollbar: 0.2rem;
    overflow-x: none;
}

::selection {
    color: black;
    background: var(--white);
}

::-webkit-scrollbar {
    position: absolute;
    width: var(--scrollbar);
    background-color: transparent;
    background: black;
}

::-moz-scrollbar {
    position: absolute;
    width: var(--scrollbar);
    background-color: transparent;
    background: black;
}

::-ms-scrollar {
    position: absolute;
    width: var(--scrollbar);
    background-color: transparent;
    background: black;
}

::scrollbar {
    position: absolute;
    width: var(--scrollbar);
    background-color: transparent;
    background: black;
}

::-webkit-scrollbar-thumb {
    background-color: var(--white);
    border-radius: 10px;
}

div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
}

div {
    font-size: 2rem;
}

.header {
    width: 100%;
    margin: 0.75rem 0;
    margin-top: 1.25rem;
}

input {
    z-index: 9;
    border: solid 2px #2e303e;
    outline: none;
    padding: 0.7em;
    border-radius: 10px;
    background: transparent;
    color: var(--white);
    max-width: 60em;
    width: 60%;
    transition: all 0.4s ease;
    caret-color: #e95379;
    display: block;
    position: relative;
    font-size: 17px;
    margin: auto;
}

input:focus {
    border: solid 2px #6c6f93;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 62em;
    width: 61%;
}

input:focus::placeholder {
    color: transparent;
    transition: all 0.1s ease;
}

html {
    margin: auto;
    width: 92%;
}
body {
    margin: 0;
    margin-right: 0.1rem;
}

h1 {
    font-size: 11vw;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}

.top-cont {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 11fr 5fr;
    grid-template-areas: "title freebox" "underTitle freebox";
    margin-bottom: 1rem;
}

.title {
    cursor: default;
    z-index: 1;
    grid-area: title;
    display: inline-block;
    color: var(--white);
    text-align: left;
    padding-left: 0.25rem;
    padding-bottom: 0.4rem;
    line-height: 7vw;
    position: relative;
    user-select: none;
    width: 100%;
    overflow-x: visible;
    font-size: 11vw;
    margin: auto;
}

.title h1 {
    margin: 0rem;
    padding: 1rem;
    font-size: 10vw;
}

.title .second {
    margin-left: 2.8rem;
    margin-bottom: 0.5rem;
}

.freebox {
    z-index: -1;
    grid-area: freebox;
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 45vw;
    grid-column: 2;
}

.underTitle {
    z-index: 2;
    grid-area: underTitle;
    display: inline-block;
    width: 100%;
    text-align: left;
    margin-top: 1.7rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.underTitle p {
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-top: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.link-cont {
    margin-top: 0.8rem;
    margin-bottom: 0.6rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.underTitle a {
    box-shadow: 0px 0px 0px 1px var(--white) inset;
    background: transparent;
    font-size: 20px;
    display: inline-block;
    padding: 10px 20px;
    color: var(--white);
    position: relative;
    z-index: 3;
    text-decoration: none;
    width: fit-content;
}

.underTitle a div {
    position: absolute;
    display: inline-block;
    height: 0%;
    top: 0;
    left: 0;
    background: var(--white);
    z-index: -1;
    overflow: hidden;
    transition: 0.2s all ease-out;
    font-size: 20px;
    width: 100%;
}

.underTitle a:hover div {
    display: inline-block;
    height: 100%;
    box-shadow: 0 0 15px var(--whiter);
}

.underTitle a:hover {
    z-index: 3;
    transition: 0.2s all ease-out;
    color: black;
}

.cardlist {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 36px;
    grid-auto-rows: minmax(250px, auto);
    margin-top: 1rem;
}

.card {
    position: relative;
    background: transparent;
    outline: 1px solid var(--white);
    color: var(--white);
    display: grid;
    padding: 1rem;
    cursor: default;
    font-family: "Figtree", sans-serif;
}

.card h2 {
    font-family: "Figtree", sans-serif;
    font-size: 30px;
    margin: auto;
    text-align: center;
    transition: all 0.2s ease-out;
    margin-bottom: 0;
    user-select: none;
}

.card:hover h2 {
    transform: translateY(-1rem);
}

.card p {
    white-space: pre-wrap;
    cursor: text;
    font-size: 16px;
    opacity: 0;
    transition: all 0.2s ease-out;
    margin: 0;
    padding: 0;
    float: left;
}
.card:hover p {
    opacity: 1;
    transform: translateY(-1rem);
}

.card .card-expand {
    width: 100%;
    background: var(--white);
    height: 0;
    bottom: 0;
    position: absolute;
    transition: height 0.2s ease-out;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px var(--whiter);
}

.card a {
    text-decoration: none;
    opacity: 0;
    transition: all 0.2s ease-out;
}

.card:hover .card-expand {
    opacity: 1;
    height: 25%;
    transition: all 0.2s ease-out;
}

.card-expand:hover a {
    opacity: 1;
}

a {
    cursor: pointer;
}

.card .tag {
    top: 9px;
    left: 12px;
    position: absolute;
    font-size: 16px;
    line-height: 20px;

    user-select: none;
}

.card .tagRight {
    top: 9px;
    right: 12px;
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    user-select: none;
}

.footer {
    position: relative;
    left: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    color: var(--white);
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.footer div {
    font-size: 1rem;
}

.footer .left {
    margin-left: 0px;
}

.footer .right {
    margin-right: 0px;
}

@media (max-width: 1000px) {
    /* half monitor */
    html {
        width: 92%;
    }

    input {
        width: 85%;
    }

    input:focus {
        width: 87%;
    }

    .title {
        text-align: center;
        padding-top: 0rem;
        padding-bottom: 10px;
        line-height: 11vw;
    }

    .title h1 {
        margin: 0rem;
        padding: 0rem;
        font-size: 11vw;
    }

    .title .second {
        margin-left: 0rem;
    }

    .underTitle p {
        padding-right: 1rem;
    }
    .top-cont {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        /* make the freebox div take up the whole column on the right */
        grid-template-areas: "title" "freebox" "underTitle";
        margin-bottom: 1rem;
    }

    .freebox {
        grid-column: 1;
        max-width: 100%;
    }

    .underTitle {
        margin-top: 0.2rem;
    }
    .link-cont {
        justify-content: space-evenly;
    }

    .cardlist {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 650px), (max-aspect-ratio: 2/3) {
    /* phone */
    .cardlist {
        grid-template-columns: 100%;
    }
}
