.App {
    text-align: center;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.App-header {
    background-color: #282c34;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

body {
    margin-left: 2rem;
    margin-right: 2rem;
}
@media (max-width: 480px) {
    /* phone */
    body {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
}
