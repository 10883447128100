:root {
    --dark: #000000;
}

html {
    background: var(--dark);
    color: #fdf0ed;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: 0;
    outline: none;
}

* {
    font-family: "Figtree", "Inter", "Roboto", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Figtree", "Inter", "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;
}
